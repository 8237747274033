<template>
  <div class="image__wrapper">
    <template v-if="overlayOpen && isMobile()">
      <div class="image__icon-wrapper" @click="$emit('toggleShowNotes')">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 59 59" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path id="a" d="M27.5 21C12.27 21 0 33.27 0 48.5S12.27 76 27.5 76 55 63.73 55 48.5 42.73 21 27.5 21zm12.347 29.983L21.459 61.697c-.364.242-.789.303-1.153.303s-.789-.06-1.153-.303C18.485 61.334 18 60.547 18 59.7V38.27c0-.847.485-1.634 1.153-1.998.668-.363 1.639-.363 2.306 0l18.449 10.715c.667.363 1.092 1.15 1.092 1.998 0 .847-.485 1.634-1.153 1.998z"/></defs><use xlink:href="#a" transform="translate(2 -20)" fill="currentColor"/></svg>
        <p>Player</p>
      </div>
    </template>
    <img class="image" :src="imagePath" :alt="alt" />
    <div class="image__overlay">
      <template v-if="overlayOpen && !isMobile()">
        <div @click="$emit('toggleShowNotes')" class="image__icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 59 59" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path id="a" d="M27.5 21C12.27 21 0 33.27 0 48.5S12.27 76 27.5 76 55 63.73 55 48.5 42.73 21 27.5 21zm12.347 29.983L21.459 61.697c-.364.242-.789.303-1.153.303s-.789-.06-1.153-.303C18.485 61.334 18 60.547 18 59.7V38.27c0-.847.485-1.634 1.153-1.998.668-.363 1.639-.363 2.306 0l18.449 10.715c.667.363 1.092 1.15 1.092 1.998 0 .847-.485 1.634-1.153 1.998z"/></defs><use xlink:href="#a" transform="translate(2 -20)" fill="currentColor"/></svg>
          <p>Player</p>
        </div>
      </template>
      <template v-else-if="!isMobile()">
        <div @click="$emit('toggleShowNotes')" class="image__icon-wrapper">
          <svg viewBox="0 0 79 56" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit" fill-rule="evenodd"><path d="M20.5 48h29V4h-29v44zM6 42.133h11.6V9.867H6v32.266zM52.4 9.867H64v32.266H52.4V9.867zM36.5 35.5H33V25h3.5v10.5zm0-14H33V18h3.5v3.5z" fill-rule="nonzero"/><path fill="none" d="M0-8h70v70H0z"/></g></svg>
          <p>Info</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/lib/helpers";

export default {
  props: {
    imagePath: { type: String, required: true },
    overlayOpen: { type: Boolean, default: false },
    alt: { type: String, required: true },
  },

  data() {
    return {
      isMobile,
    };
  },
};
</script>

<style lang="scss" scoped>
.image__wrapper {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  &:hover {
    cursor: pointer;

    .image__overlay {
      opacity: 1;
    }
  }

  svg use {
    fill: var(--secondary-color);
  }
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(black, 0.6);
  height: 170px;
  width: 170px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.image__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  height: 100%;
  text-align: center;
  border-radius: 4px;

  p {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
  }

  svg {
    height: 60px;

    g {
      fill: var(--secondary-color);
    }
  }
}

.image {
  height: 170px;
  width: 170px;
  box-shadow: 0 1px 2px rgba(black, 0.6);
  border-radius: 4px;
}

@media screen and (max-width: $breakpoint--m) {
  .image {
    height: 160px;
    width: 160px;
  }

  .image__overlay {
    height: 160px;
    width: 160px;
  }
}

@media screen and (max-width: $breakpoint--s) {
  .image__wrapper {
    &:hover {
      .image__overlay {
        opacity: 0;
      }
    }
  }

  .image__overlay,
  .image,
  .image__icon-wrapper {
    height: 76px;
    width: 76px;

    svg {
      height: 32px;
      width: 32px;
    }
  }

  .image__overlay {
    background: transparent;
  }

  .image__icon-wrapper {
    color: var(--text-color);
    position: relative;
    top: -4px;

    svg use {
      fill: var(--text-color);
    }

    svg g {
      fill: var(--text-color);
    }

    &:hover {
      background: rgba(black, 0.2);
    }
  }

  .image__overlay {
    bottom: 0;
    top: initial;
  }
}
</style>
