<template>
  <div :class="[{ '--small': small },'player-play']" @click="$emit('togglePlayback')">
    <template v-if="isPlaying">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 59 59" v-bind:svg-inline="''" v-bind:title="'Pause'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-6.4%" y="-4.5%" width="112.7%" height="112.7%" filterUnits="objectBoundingBox" id="a"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.167613636 0" in="shadowBlurOuter1"/></filter><path d="M97.5 3C112.73 3 125 15.27 125 30.5S112.73 58 97.5 58 70 45.73 70 30.5 82.27 3 97.5 3zM91 18.5a4 4 0 00-4 4v17a4 4 0 108 0v-17a4 4 0 00-4-4zm13 0a4 4 0 00-4 4v17a4 4 0 108 0v-17a4 4 0 00-4-4z" id="b"/></defs><g transform="translate(-68 -2)" fill="currentColor"><use filter="url(#a)" xlink:href="#b"/><use xlink:href="#b"/></g></svg>
    </template>
    <template v-else>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 59 59" v-bind:svg-inline="''" v-bind:title="'Play'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path id="a" d="M27.5 21C12.27 21 0 33.27 0 48.5S12.27 76 27.5 76 55 63.73 55 48.5 42.73 21 27.5 21zm12.347 29.983L21.459 61.697c-.364.242-.789.303-1.153.303s-.789-.06-1.153-.303C18.485 61.334 18 60.547 18 59.7V38.27c0-.847.485-1.634 1.153-1.998.668-.363 1.639-.363 2.306 0l18.449 10.715c.667.363 1.092 1.15 1.092 1.998 0 .847-.485 1.634-1.153 1.998z"/></defs><use xlink:href="#a" transform="translate(2 -20)" fill="currentColor"/></svg>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isPlaying: { type: Boolean, required: true },
    small: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.player-play {
  cursor: pointer;
  height: 60px;
  width: 60px;
  min-width: 60px;

  svg use {
    fill: var(--primary-color);
  }

  &.--small {
 height: 24px;
  width: 24px;
  min-width: 24px;

  }
}

@media screen and (max-width: $breakpoint--m) {
  .player-play {
    height: 55px;
    width: 55px;
    min-width: 55px;
    margin-left: 4px;
  }
}
</style>
